import {
    getCurrentLangKey as getCurrentLangKeyBuilder,
    getBrowserLanguage,
    getLangs,
    getUrlForLang
} from 'ptz-i18n';

export const langKeys = ['uk', 'ru'];

export const langKeyDefault = 'uk';

export const getCurrentLangKey = (pathname: string) =>
    getCurrentLangKeyBuilder(langKeys, langKeyDefault, pathname);

export const getBrowserLangKey = getBrowserLanguage;

export const getLangLink = (langKey: string) => `/${langKey}/`;

export const getHomeLink = (currentLang: string) =>
    getLangLink(currentLang).replace(getLangLink(langKeyDefault), '/');

export const getLangsData = (pathname: string) => {
    const currentLang = getCurrentLangKey(pathname);
    return getLangs(
        langKeys,
        currentLang,
        getUrlForLang(getHomeLink(currentLang), pathname)
    ).map((item: any) => ({
        ...item,
        link: item.link.replace(getLangLink(langKeyDefault), '/')
    }));
};
