export const setCookie = (name: string, value: string, expiresDays: number) => {
    const date = new Date(Date.now() + expiresDays * 86400e3).toUTCString();
    document.cookie = `${name}=${encodeURIComponent(
        value
    )}; path=/; expires=${date}`;
};

export const getCookie = (name: string) => {
    let matches = document.cookie.match(
        new RegExp(
            '(?:^|; )' +
                name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
                '=([^;]*)'
        )
    );
    return matches ? decodeURIComponent(matches[1]) : undefined;
};
