import './src/styles/global.css';
import {
    langKeyDefault,
    getCurrentLangKey,
    getBrowserLangKey,
    getLangLink,
    getLangsData
} from './src/data/languages';
import { getCookie, setCookie } from './src/utils/cookie';

function redirectToLang(): void {
    const { pathname } = window.location;
    const langs = getLangsData(pathname);
    const currentLangKey = getCurrentLangKey(pathname);
    const browserLangKey = getBrowserLangKey();
    const currentLang = langs.find(({ langKey }) => langKey == currentLangKey);
    const browserLang = langs.find(({ langKey }) => langKey == browserLangKey);

    if (
        currentLangKey == langKeyDefault &&
        pathname == getLangLink(langKeyDefault)
    ) {
        setCookie('redirectToLangOnce', currentLangKey, 30);
        window.location.replace('/');
        return;
    }

    if (
        currentLangKey == langKeyDefault &&
        currentLang &&
        browserLang &&
        currentLang.link != browserLang.link &&
        getCookie('redirectToLangOnce') == undefined
    ) {
        setCookie('redirectToLangOnce', browserLangKey, 30);
        window.location.replace(browserLang.link);
        return;
    }
}

export const onClientEntry = () => {
    redirectToLang();
};
